<template>
  <section class="categoryes">
    <div class="container">
      <div class="categoryes__wrapper">
        <div
          class="categoryes__item"
          v-for="(b, idx) in body"
          :key="idx"
          @click.prevent="goTo(b.replaceAll(' ', '-'))"
        >
          {{ b }}
          <div class="categoryes__image">
            <img
              :src="require(`@/assets/images/allroad.webp`)"
              alt=""
              v-if="b === 'Внедорожник'"
            />
            <img
              :src="require(`@/assets/images/miniven.webp`)"
              alt=""
              v-if="b === 'Минивэн'"
            />
            <img
              :src="require(`@/assets/images/sedan.webp`)"
              alt=""
              v-if="b === 'Седан'"
            />
            <img
              :src="require(`@/assets/images/hatchback.webp`)"
              alt=""
              v-if="b === 'Хетчбэк'"
            />
            <img
              :src="require(`@/assets/images/liftback.webp`)"
              alt=""
              v-if="b === 'Лифтбек'"
            />
            <img
              :src="require(`@/assets/images/wagon.webp`)"
              alt=""
              v-if="b === 'Универсал'"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  props: ['model'],
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const body = computed(() => {
      if (route.path.indexOf('/cars/') !== -1) {
        return store.getters.BODY_NEW;
      } else {
        return store.getters.BODY;
      }
    });

    const scrollTo = (id) => {
      var element = document.getElementById(id);
      var headerOffset = 75;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    };
    const bodys = store.getters.BODY_TRANSLATE;
    const goTo = (id) => {
      console.log(id);
      const bdy = bodys.find((item) => id === item.ru).en;
      if (route.path.indexOf('/cars/') !== -1) {
        router.push({
          path: `/cars/${bdy.replaceAll(' ', '-').toLowerCase()}/`,
          params: {
            model: id,
          },
        });
      } else {
        router.push({
          path: `/used-cars/${bdy.replaceAll(' ', '-').toLowerCase()}/`,
          params: {
            model: id,
          },
        });
      }
      store.dispatch('GET_FILTERS', {
        mark: '',
        compl: '',
        model: '',
        kpp: '',
        body_type: id,
      });
      scrollTo('cars-cards');
    };

    return {
      body,
      goTo,
    };
  },
};
</script>

<style lang="sass">
.categoryes
	margin-top: 120px
	&__wrapper
		gap: 20px
		display: grid
		justify-content: center
		grid-template-columns: repeat(6, 1fr)
	&__item
		background-color: $accent
		color: #fff
		width: 100%
		border-radius: 10px
		height: 150px
		cursor: pointer
		padding: 20px
		font-weight: bold
		position: relative
		overflow: hidden
		transition: .3s ease
		&:hover
			background-color: #ec262d
			.categoryes__image
				transform: translateX(0)
	&__image
		position: absolute
		right: 0
		bottom: 0
		height: 60%
		transition: .3s ease
		transform: translateX(20px)
		img
			display: block
			height: 100%
			width: auto
@include sm-tablets
	.categoryes
		&__wrapper
			display: flex
			flex-wrap: wrap
		&__item
			width: 150px
</style>
