import { createStore } from 'vuex';
import Axios from 'axios';
import dataCars from '../../public/dataCars.json';
export default createStore({
  state: {
    body_translate: [
      {
        ru: 'Седан',
        en: 'Sedan',
      },
      {
        ru: 'Минивэн',
        en: 'Miniven',
      },
      {
        ru: 'Хетчбэк',
        en: 'Hatchback',
      },

      {
        ru: 'Внедорожник',
        en: 'Allroad',
      },
      {
        ru: 'Лифтбек',
        en: 'Liftback',
      },
      {
        ru: 'Универсал',
        en: 'Wagon',
      },
    ],
    marks: [
      'Mercedes-Benz',
      'Audi',
      'SsangYong',
      'KIA',
      'Nissan',
      'Skoda',
      'Volvo',
      'Ford',
      'Hyundai',
      'Mitsubishi',
      'Volkswagen',
      'Mazda',
      'Toyota',
      'Lexus',
      'Chevrolet',
      'Fiat',
      'Honda',
      'Jeep',
      'Land Rover',
      'Jaguar',
    ],
    cars: [],
    filteredCars: [],
    filters: {
      mark: '',
      model: '',
      compl: '',
      kpp: '',
      body_type: '',
      priceFrom: 0,
      priceTo: 9999999,
      carsView: 8,
      priceFromLimit: 100000,
      priceToLimit: 9999999,
      sorted: '',
    },
    loaded: false,
    phone: '+79812070000', //Телефон во всех ссылках
    time_work_from: 9, // Начало работы колл-центра (в часах)
    time_work_to: 21, // Конец работы колл-центра (в часах)
    icons: [
      [
        [59.993622, 30.244632],
        'г. Санкт-Петербург, Стародеревенская ул., 11к2',
      ],
    ],
    mapZoom: 9,
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
  },
  mutations: {
    SET_CARS_TO_STATE: (state, cars) => {
      state.cars = cars;
    },
    SET_FILTERS: (
      state,
      {
        compl = '',
        priceFrom = 0,
        priceTo = 9999999,
        mark = '',
        model = '',
        body_type = '',
        kpp = '',
        carsView = 8,
        priceFromLimit,
        priceToLimit,
        sorted = '',
      }
    ) => {
      state.filters.compl = compl;
      state.filters.mark = mark;
      state.filters.model = model;
      state.filters.kpp = kpp;
      state.filters.body_type = body_type;
      state.filters.priceFrom = priceFrom;
      state.filters.priceTo = priceTo;
      state.filters.carsView = carsView;
      state.filters.sorted = sorted;
      if (priceFromLimit) state.filters.priceFromLimit = priceFromLimit;
      if (priceToLimit) state.filters.priceToLimit = priceToLimit;
    },
    SET_LOADED: (state) => {
      state.loaded = true;
    },
    SET_FILTERED_CARS: (state) => {
      let cars = state.cars;
      if (state.filters.mark)
        cars = cars.filter((car) => car.mark_id === state.filters.mark);
      if (state.filters.model)
        cars = cars.filter((car) => car.model === state.filters.model);
      if (state.filters.kpp)
        cars = cars.filter((car) => car.kpp === state.filters.kpp);
      if (state.filters.body_type)
        cars = cars.filter((car) => car.body_type === state.filters.body_type);
      if (state.filters.compl)
        cars = cars.filter(
          (car) => car.modification_id === state.filters.compl
        );
      cars = cars.filter(
        (car) =>
          +state.filters.priceFrom <= +car.price &&
          +car.price <= +state.filters.priceTo
      );
      if (state.filters.sorted === 'asc') {
        cars = cars.sort((a, b) => (+a.price < +b.price ? -1 : 1));
      }
      if (state.filters.sorted === 'desc') {
        cars = cars.sort((a, b) => (+a.price > +b.price ? -1 : 1));
      }

      state.filteredCars = cars;
    },
  },
  actions: {
    GET_CARS_FROM_STOCK({ commit, dispatch }) {
      return Axios(window.location.origin + '/backend/data.json', {
        method: 'GET',
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
        .then((res) => {
          const data = res.data;
          data.push(...dataCars);
          commit('SET_CARS_TO_STATE', data);
          const priceFromLimit = Math.floor(+data[0].price / 100000) * 100000;
          const priceToLimit =
            Math.round(+data[data.length - 1].price / 100000) * 100000;
          dispatch('GET_FILTERS', {
            mark: '',
            model: '',
            compl: '',
            kpp: '',
            body_type: '',
            priceFromLimit,
            priceToLimit,
            carsView: 8,
            sorted: '',
          });
          dispatch('GET_LOADED');
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    GET_FILTERS(
      { commit, dispatch },
      {
        mark,
        model,
        compl,
        kpp,
        body_type,
        carsView,
        priceFrom,
        priceTo,
        priceFromLimit,
        priceToLimit,
        sorted,
      }
    ) {
      commit('SET_FILTERS', {
        mark,
        model,
        compl,
        kpp,
        body_type,
        carsView,
        priceFrom,
        priceFromLimit,
        priceToLimit,
        priceTo,
        sorted,
      });
      dispatch('GET_FILTERED_CARS');
    },
    GET_FILTERED_CARS({ commit }) {
      commit('SET_FILTERED_CARS');
    },
    GET_LOADED({ commit }) {
      commit('SET_LOADED');
    },
  },
  getters: {
    CARS: (s) => s.cars,
    // CARS_COMPLS: (s) => s.car_compls,
    CAR: (s) => (id) => s.cars.find((car) => car.id === id),
    MODELS: (s) => {
      let cars = s.cars;
      if (s.filters.mark)
        cars = s.cars.filter((car) => car.mark_id === s.filters.mark);
      return [...new Set(Array.from(cars, ({ model }) => model))];
      // const uniques = {};
      // return cars.reduce((result, car) => {
      //   if (uniques[car.model]) return result;
      //   uniques[car.model] = car;
      //   return [...result, car];
      // }, []);
    },
    MARKS: (s) => {
      const cars = s.cars.filter((car) => (+car.run > 1 ? true : false));
      return [...new Set(Array.from(cars, ({ mark_id }) => mark_id))];
    },
    MARKS_NEW: (s) => {
      const cars = s.cars.filter((car) => (+car.run === 1 ? true : false));
      return [...new Set(Array.from(cars, ({ mark_id }) => mark_id))];
    },
    BODY: (s) => {
      const cars = s.cars.filter((car) => (+car.run > 1 ? true : false));
      return [...new Set(Array.from(cars, ({ body_type }) => body_type))];
    },
    BODY_NEW: (s) => {
      const cars = s.cars.filter((car) => (+car.run === 1 ? true : false));
      return [...new Set(Array.from(cars, ({ body_type }) => body_type))];
    },
    BODY_TRANSLATE: (s) => {
      return s.body_translate;
    },
    VYKUP_MARKS: (s) => {
      return s.marks;
    },
    PHONE: (s) => s.phone,
    TIME_WORK_FROM: (s) => s.time_work_from,
    TIME_WORK_TO: (s) => s.time_work_to,
    INTERESTED_CARS:
      (s) =>
      ({ price, id }) =>
        s.cars.filter(
          (car) =>
            +car.price >= +price &&
            +car.price <= +price + 200000 &&
            car.id !== id
        ),

    COMPLS: (s) => {
      let marks = s.cars;
      if (s.filters.mark)
        marks = s.cars.filter(
          (car) => car.mark_id === s.filters.mark && car.modification_id
        );
      if (s.filters.model)
        marks = s.cars.filter(
          (car) => car.model === s.filters.model && car.model
        );

      const uniques = {};
      return marks.reduce((result, car) => {
        if (uniques[car.modification_id]) return result;
        uniques[car.modification_id] = car;
        return [...result, car.modification_id];
      }, []);
    },
    FILTERED_CARS: (s) => s.filteredCars,
    FILTERS: (s) => s.filters,
    LOADED: (s) => s.loaded,
  },
});
